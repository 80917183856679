nav.mx-auto {
    position: fixed;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    /* add any other styles you need for the navigation bar */
  }