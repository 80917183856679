@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.hero-screen {
  background-color: #ffffff;
}

.hero-screen h1 {
  animation: fadeInUp 0.8s ease;
}

.hero-screen p {
  animation: fadeInUp 1s ease;
}

.hero-screen button {
  animation: fadeInUp 1.2s ease;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}





.small-image {
  width: 80%;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
}

/* icolour changin */
@keyframes gradientChange {
  0% {
    background: linear-gradient(to right, #f700ff, #4532ec);
  }

  50% {
    background: linear-gradient(to right, #007cf0, #00ffc8);
  }

  100% {
    background: linear-gradient(to right, #f700ff, #b00efc);
  }
}

.gradient-text {
  background: linear-gradient(to right, #05eeff, #b700ff);
  background-size: 200% 200%;
  animation: gradientChange 16s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* icolour changin */