.AutoScalingButton {
  background-image: linear-gradient(to bottom, #09c6eb, #139ceb);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 24px 120px;
  border-radius: 0px;
  transition: transform 0.1s ease-in-out;
}

.AutoScalingButton:hover {
  transform: scale(1.1);
}


.partner-inner img {
  transition: filter 0.3s ease;
}

.partner-inner img:hover {
  filter: brightness(100%);
}

.partner-inner img:not(:hover) {
  filter: brightness(45.2%);
  color: rgb(236, 236, 236);
}

.slick-slide {
    padding: 0 8px; /* Adjust the value to increase/decrease the gap */
}

@keyframes hoverEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.05) rotate(2deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.img-hover-effect:hover {
  animation: hoverEffect 0.8s ease-in-out;
}


