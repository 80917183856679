@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap');
@tailwind base;


:root {
  --primary: rgba(15, 43, 96);
  --secondary: rgb(15, 43, 96);
}

* {
  scrollbar-width: none;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  border-radius: 3px;
}

*::-webkit-scrollbar-track {
  background-color: var(--secondary);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 0px;
  border: 0px solid var(--primary);
}

@tailwind components;

@tailwind utilities {

  .shadow-inner-glow {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  }
};

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* //reviews */
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: '' !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: '' !important;
}

.swiper-button-next svg,
.swiper-button-prev svg {
    width: 24px !important;
    height: 24px !important;
}

.swiper-button-next,
.swiper-button-prev {
    position: relative !important;
    margin-top: 32px;
}

.swiper-slide.swiper-slide-active {
    --tw-border-opacity: 1 !important;
    border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.swiper-slide.swiper-slide-active > .swiper-slide-active\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}

.swiper-slide.swiper-slide-active > .flex .grid .swiper-slide-active\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}
/* //reviews */
/* snowfasll */

.snowflake-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

.snowflake {
  position: absolute;
  top: -5%;
  width: 10px;
  height: 10px;
  background: rgb(218, 214, 214);
  border-radius: 50%;
  opacity: 0.8;
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  100% {
    transform: translateY(110vh);
    opacity: 0.4;
  }
}