.projects-card-wrapper__wrapper {
    @apply flex justify-center items-center;
}

.projects-card-wrapper {
    @apply bg-white rounded-lg shadow-lg p-6 transform translate-y-0 opacity-100 visible transition-transform duration-500;
}

.projects-card-image-wrapper {
    @apply flex justify-center items-center mb-4;
}

.projects-card-icon {
    @apply w-12 h-12;
}

.projects-card-content-wrapper {
    @apply text-center;
}

.projects-card-heading {
    @apply text-2xl font-bold mb-2;
}

.projects-card-text {
    @apply text-gray-600 mb-4;
}

.projects-card-name-wrapper {
    @apply flex flex-col items-center;
}

.projects-card-name {
    @apply font-semibold text-lg;
}

.projects-card-designation {
    @apply text-gray-500;
}