@media (max-width: 640px) {

    /* Targeting mobile devices */
    button {
        width: 100%;
        font-size: 16px;
        margin-bottom: 10px;
        margin-right: 0;
    }

    button span {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* Additional styles for other breakpoints if needed */
@media (min-width: 641px) and (max-width: 1024px) {
    button {
        width: auto;
        font-size: 18px;
        margin-bottom: 8px;
        margin-right: 5px;
    }

    button span {
        padding-left: 30px;
        padding-right: 30px;
    }
}