.watermark {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

/* cards */
.carousel-container {
  width: 100%;
}

.carousel {
  display: flex;
  transform: translateX(0);
  animation: slide 10s infinite;
}

@keyframes slide {

  0%,
  100% {
    transform: translateX(0);
  }

  33% {
    transform: translateX(-100%);
  }

  66% {
    transform: translateX(-200%);
  }
}

.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

/* cards */


/* cards underline */
.link-with-arrow {
  position: relative;
  text-decoration: none;
  color: #3182ce;
}

.link-with-arrow:hover {
  text-decoration: underline;
  
}

.link-with-arrow::after {
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.link-with-arrow:hover::after {
  opacity: 1;
}



/* cards underline */